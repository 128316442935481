import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CapitalizeFirstPipe } from '@core/pipes/capitalize-first.pipe';
import { AuthenticationService } from '@core/services/authentication.service';
import { FilesService } from '@core/services/files.service';
import { ModalsBaseService } from '@core/services/modals.service';
import { NotificationService } from '@core/services/notifications.service';
import { PermissionsService } from '@core/services/permissions.service';
import {
  EDataMutation,
  IPostHogEventConfig,
} from '@core/services/posthog.service';
import { environment } from '@env/environment';
import { MODAL_CONFIGS } from '@meeting/modals.config';
import {
  ECampaignStatus,
  EMeetingStatuses,
  EParticipantRoles,
  ICampaign,
  ICampaignResult,
  ICreateCampaign,
  IFilterFindCampaigns,
  IMeeting,
  IMeetingResult,
} from '@meeting/models/meeting.model';
import { TranslocoService } from '@ngneat/transloco';
import * as qs from 'qs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting`;

  private _campaigns$$: BehaviorSubject<ICampaign[]> = new BehaviorSubject<
    ICampaign[]
  >([]);

  campaigns$ = this._campaigns$$.asObservable();

  posthogEvent$$: BehaviorSubject<{
    mutation: EDataMutation;
    campaign: ICampaignResult;
  }> = new BehaviorSubject<
    | {
        mutation: EDataMutation;
        campaign: ICampaignResult;
      }
    | undefined
  >(undefined);

  addParticipantsPosthogEvent$$: BehaviorSubject<
    | {
        campaignId: string;
        createdMeetings: string[];
      }
    | undefined
  > = new BehaviorSubject<
    | {
        campaignId: string;
        createdMeetings: string[];
      }
    | undefined
  >(undefined);

  downloadedCampaignReportsEvent$$: BehaviorSubject<void | undefined> =
    new BehaviorSubject<void | undefined>(undefined);

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  loading$ = this._loading$$.asObservable();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _notificationService: NotificationService,
    private readonly _translocoService: TranslocoService,
    private readonly _capitalizePipe: CapitalizeFirstPipe,
    private readonly _modalsService: ModalsBaseService,
    private readonly _filesService: FilesService,
    private readonly _authService: AuthenticationService,
    private readonly _permissionsService: PermissionsService,
  ) {
    this._authService.currentUser$.subscribe((user) => {
      if (
        user &&
        this._permissionsService._userPermissions.includes('admin:access')
      ) {
        this.refreshStore();
      }
    });
  }
  refreshStore() {
    this.getCampaignsWithFilters$({ size: 100000 })
      .pipe(
        take(1),
        tap(() => this._loading$$.next(true)),
        map((campaigns) => campaigns.data),
      )
      .subscribe((campaigns) => {
        this._campaigns$$.next(campaigns);
        this._loading$$.next(false);
      });
  }

  getCampaignsWithFilters$(filters?: IFilterFindCampaigns) {
    let endpoint = `${environment.API_URL}/meeting/meetings/campaign`;
    if (filters) {
      endpoint += '?' + qs.stringify(filters);
    }
    return this._httpClient.get<{
      data: ICampaignResult[];
      pagination: { total: number; page: number; totalPages: number };
    }>(endpoint);
  }

  getOneCampaign$(campaignId: string): Observable<ICampaignResult> {
    return this._httpClient.get<ICampaignResult>(
      this._ENDPOINT_URL + '/meetings/campaign/' + campaignId,
    );
  }

  getCampaignParticipantsById$(
    campaignId: string,
  ): Observable<ICampaignResult & { meetings: IMeetingResult[] }> {
    return this._httpClient.get<
      ICampaignResult & { meetings: IMeetingResult[] }
    >(
      this._ENDPOINT_URL + '/meetings/campaign/' + campaignId + '/participants',
    );
  }

  startOneCampaign$(
    campaignId: string,
    campaignTitle?: string,
  ): Observable<ICampaignResult> {
    return this._httpClient
      .post<ICampaignResult>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/start`,
        {},
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.CAMPAIGN_HAS_STARTED_TOAST',
            { value: campaignTitle ?? campaignId },
          );
          this.refreshStore();
        }),
      );
  }

  downloadAvailableCampaignExports(campaign: ICampaignResult): void {
    if (campaign.status === ECampaignStatus.FINISHED) {
      this._processDownloadAvailableCampaignExports(campaign);
      return;
    }

    this._modalsService
      .openConfirmationModal$(
        MODAL_CONFIGS.PARTIAL_EXPORT_CAMPAIGN_MODAL_CONFIG,
      )
      .subscribe((confirm) => {
        confirm && this._processDownloadAvailableCampaignExports(campaign);
      });
  }

  private _processDownloadAvailableCampaignExports(
    campaign: ICampaignResult,
  ): void {
    const endpoint = `meeting/meetings/campaign/${campaign.id}/reports`;
    const posthogConfig: IPostHogEventConfig = {
      event: this.downloadedCampaignReportsEvent$$,
    };

    this._filesService.downloadFile(endpoint, 'zip', undefined, posthogConfig);
  }

  downloadXLSExport(campaign: ICampaignResult): void {
    if (campaign.status === ECampaignStatus.FINISHED) {
      this._processDownloadAvailableXLSCampaignExports(campaign);
      return;
    }

    this._modalsService
      .openConfirmationModal$(
        MODAL_CONFIGS.PARTIAL_EXPORT_CAMPAIGN_MODAL_CONFIG,
      )
      .subscribe((confirm) => {
        confirm && this._processDownloadAvailableXLSCampaignExports(campaign);
      });
  }

  private _processDownloadAvailableXLSCampaignExports(
    campaign: ICampaignResult,
  ): void {
    const endpoint = `meeting/meetings/campaign/${campaign.id}/export`;

    this._filesService.downloadFile(endpoint, 'xls');
  }

  /**
   * Get current (active) meeting's step as text
   * @param meeting
   * @returns string, already Transloco piped
   */
  getMeetingCurrentStepAsText(meeting: IMeeting | IMeetingResult): string {
    if (
      [
        EMeetingStatuses.READY_TO_SIGN,
        EMeetingStatuses.STARTED,
        EMeetingStatuses.CREATED,
      ].includes(meeting.status)
    ) {
      return '0/2';
    }

    if (
      [
        EMeetingStatuses.PREPARED_BY_REVIEWEE,
        EMeetingStatuses.FINISHED_BY_REVIEWEE,
      ].includes(meeting.status)
    ) {
      const reviewer = meeting.participants.find(
        (user) => user.role === EParticipantRoles.REVIEWER,
      );
      return this._translocoService.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_FOR',
        {
          username: `${this._capitalizePipe.transform(reviewer.firstName)} ${reviewer.lastName}`,
        },
      );
    }

    if (
      [
        EMeetingStatuses.PREPARED_BY_REVIEWER,
        EMeetingStatuses.FINISHED_BY_REVIEWER,
      ].includes(meeting.status)
    ) {
      const reviewee = meeting.participants.find(
        (user) => user.role === EParticipantRoles.REVIEWEE,
      );
      return this._translocoService.translate(
        'MEETING.TIMELINE.TOOLTIP_WAITING_FOR',
        {
          username: `${this._capitalizePipe.transform(reviewee.firstName)} ${reviewee.lastName}`,
        },
      );
    }

    // TO CONDUCT, CLOSED BY BOTH
    return '-';
  }

  openDeleteCampaignModal$(campaignId: string): Observable<boolean> {
    return this._modalsService
      .openConfirmationModal$(MODAL_CONFIGS.DELETE_CAMPAIGN_MODAL_CONFIG)
      .pipe(
        switchMap((confirm) => {
          if (!confirm) return of(false);
          return this.delete$(campaignId).pipe(map((res) => !!res));
        }),
      );
  }

  delete$(id: string): Observable<ICampaignResult> {
    return this._httpClient
      .delete<ICampaignResult>(`${this._ENDPOINT_URL}/meetings/campaign/${id}`)
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.TOAST_CAMPAIGN_DELETED',
          );
          this.refreshStore();
        }),
      );
  }

  create$(campaign: ICreateCampaign): Observable<ICampaignResult> {
    return this._httpClient
      .post<ICampaignResult>(
        this._ENDPOINT_URL + '/meetings/campaign',
        campaign,
      )
      .pipe(
        tap((campaign) => {
          this.posthogEvent$$.next({
            mutation: EDataMutation.CREATED,
            campaign,
          });
          this._notificationService.showSuccess(
            'MEETING.TOAST_CAMPAIGN_CREATED',
            { value: campaign.title },
          );
          this.refreshStore();
        }),
      );
  }

  patchCampaignEndDate$(campaignId: string, newEndDate: string) {
    return this._httpClient
      .patch<ICampaignResult>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/end-date`,
        { endDate: newEndDate },
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.TOAST_DATES_HAS_BEEN_UPDATED',
          );
          this.refreshStore();
        }),
      );
  }

  sendCampaignReminder$(campaignId: string) {
    return this._httpClient
      .post<ICampaignResult>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/remind`,
        {},
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.TOAST_REMINDERS_HAS_BEEN_SEND',
          );
        }),
      );
  }

  addCampaignParticipants$(
    campaignId: string,
    participants: Array<{ reviewee: { id: number }; reviewer: { id: number } }>,
  ) {
    return this._httpClient
      .put<{
        meetings: string[];
      }>(
        `${this._ENDPOINT_URL}/meetings/campaign/${campaignId}/participants`,
        participants,
      )
      .pipe(
        tap((res) => {
          this.addParticipantsPosthogEvent$$.next({
            campaignId,
            createdMeetings: res.meetings,
          });

          this._notificationService.showSuccess(
            participants.length === 1
              ? 'MEETING.TOAST_PARTICIPANT_HAS_BEEN_ADDED'
              : 'MEETING.TOAST_PARTICIPANTS_HAVE_BEEN_ADDED',
          );
          this.refreshStore();
        }),
      );
  }
}
