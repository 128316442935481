import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import {
  EQuestionsTargets,
  ICreateTemplateNote,
  IPatchTemplateNote,
  ITemplateQuestion,
  ITemplateResult,
} from '@meeting/models/meeting-template.model';
import { IType } from '@meeting/models/meeting-type.interface';
import { MeetingTypesService } from '@meeting/services/meeting-type.service';
import { TalentLocale } from '@models/locales';
import { accordion } from '@shared/animations/animations';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'meeting-template-readonly',
  templateUrl: './meeting-template-readonly.component.html',
  styleUrls: ['./meeting-template-readonly.component.scss'],
  animations: [accordion],
})
export class MeetingTemplateReadonlyComponent implements OnInit {
  template$$ = new BehaviorSubject<ITemplateResult | undefined>(undefined);
  @Input()
  public set template(newTemplate) {
    this.template$$.next(newTemplate);
  }
  public get template() {
    return this.template$$.value;
  }
  @Input() includeAnswerPreview = false;

  @Input() isIntroductionExpanded = false;

  typeId$$ = new BehaviorSubject<string | undefined>(undefined);
  type$ = new Observable<IType | undefined>(undefined);

  @Input() locale: Observable<TalentLocale> =
    this._authService.currentUser$.pipe(map((user) => user?.locale));

  questionsArray: Array<ITemplateQuestion> = [];
  notesArray: Array<ICreateTemplateNote> = [];

  // This array is needed for display purposes
  questionsAndNotesArrayReviewee: Array<
    | { type: 'question'; content: ITemplateQuestion }
    | { type: 'note'; content: ICreateTemplateNote | IPatchTemplateNote }
  > = [];

  questionsAndNotesArrayReviewer: Array<
    | { type: 'question'; content: ITemplateQuestion }
    | { type: 'note'; content: ICreateTemplateNote | IPatchTemplateNote }
  > = [];

  constructor(
    private _authService: AuthenticationService,
    private readonly _meetingTypesService: MeetingTypesService,
  ) {}

  ngOnInit(): void {
    this.template$$.subscribe((template) => {
      this.typeId$$.next(template.typeId);
      this.questionsArray = template.questions;
      this.notesArray = template.notes;

      this._updateQuestionsAndNotesArray();
    });

    this.type$ = this.typeId$$.pipe(
      switchMap((typeId) => this._meetingTypesService.getTypeById$(typeId)),
    );
  }

  getQuestionsCount(target: EQuestionsTargets) {
    return this.questionsArray.filter(
      (q) =>
        q.target === EQuestionsTargets.BOTH ||
        q.target === EQuestionsTargets[target],
    ).length;
  }

  private _updateQuestionsAndNotesArray() {
    const formattedQuestionArray: Array<{
      type: 'question';
      content: ITemplateQuestion;
    }> = this.questionsArray.map((q) => ({ content: q, type: 'question' }));
    const formattedNoteArray: Array<{
      type: 'note';
      content: ICreateTemplateNote;
    }> = this.notesArray.map((n) => ({ content: n, type: 'note' }));
    const formattedMergedArray: Array<
      | { type: 'note'; content: ICreateTemplateNote }
      | { type: 'question'; content: ITemplateQuestion }
    > = [...formattedQuestionArray, ...formattedNoteArray];

    this.questionsAndNotesArrayReviewee = formattedMergedArray
      .sort((a, b) => a.content.order - b.content.order)
      .filter((qN) =>
        qN.type === 'question'
          ? [EQuestionsTargets.REVIEWEE, EQuestionsTargets.BOTH].includes(
              qN.content.target,
            )
          : qN.type === 'note',
      );

    this.questionsAndNotesArrayReviewer = formattedMergedArray
      .sort((a, b) => a.content.order - b.content.order)
      .filter((qN) =>
        qN.type === 'question'
          ? [EQuestionsTargets.REVIEWER, EQuestionsTargets.BOTH].includes(
              qN.content.target,
            )
          : qN.type === 'note',
      );
  }
}
