import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { talentLocales } from '@core/constants';
import { PostHogService } from '@core/services/posthog.service';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    private _transloco: TranslocoService,
    @Inject(DOCUMENT) private _document: Document,
    private readonly postHogService: PostHogService,
  ) {}

  ngOnInit() {
    const localeString = talentLocales.join('|');
    const regex = `/${localeString}/`;

    const browserLang = getBrowserLang().match(regex) ? getBrowserLang() : 'fr';

    // set default document language, the proper lang
    // is set when the bootstrap data is received (see auth effects)
    this._document.documentElement.lang = browserLang;

    this._transloco.setDefaultLang(browserLang);
    this._transloco.setActiveLang(browserLang);

    this.postHogService.emitAccessEvent$$.next();
  }
}
