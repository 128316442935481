<div *ngIf="template"
     class="main-container">
  <p *ngIf="(type$ | async) as type"
     class="px-6 mb-6 card-header">{{
    template.title[locale | async] }}</p>
  <div class="px-6 py-5 mb-5 whitespace-pre-line bg-grey-1">
    <p (click)="isIntroductionExpanded = !isIntroductionExpanded"
       class="flex flex-row items-center mb-1 font-semibold cursor-pointer">
      <i class="mr-2 material-icons expand-icon"
         [ngClass]="{expanded: isIntroductionExpanded}">keyboard_arrow_down</i>
      <span class="break-all">{{ 'MEETING.MEETING_INTRODUCTION' | transloco }}</span>
    </p>
    <p *ngIf="isIntroductionExpanded"
       [@accordion]>{{ template.description | localize }}</p>

  </div>
  <div class="px-6">
    <div class="flex flex-row items-center gap-2 mb-5 font-semibold">
      <p>
        {{ 'GLOBAL.LANGUAGE' | transloco }} : </p>
      <p class="language">
        <ng-container *ngFor="let locale of template.title | keyvalue; let isLast = last">
          {{ 'GLOBAL.LANGUAGES_' + locale.key.toUpperCase() | transloco }}{{ !isLast ? ', ' : '' }}
        </ng-container>
      </p>
    </div>

    <section>
      <mat-tab-group mat-stretch-tabs="false"
                     mat-align-tabs="start"
                     fitInkBarToContent
                     class="h-full">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>{{ 'MEETING.REVIEWEE_QUESTION' | plural: getQuestionsCount('REVIEWEE') | transloco | capitalize }}</span>
            <span class="question-count">{{ getQuestionsCount('REVIEWEE') }}</span>
          </ng-template>
          <div *ngFor="let questionOrNote of questionsAndNotesArrayReviewee; let i = index">
            <div class="flex items-start py-5"
                 *ngIf="questionOrNote.type === 'question'">
              <p class="mr-5 font-semibold text-grey-7">{{i+1}}.</p>
              <meeting-question [question]="questionOrNote.content"
                                [readOnlyQuestion]="true"
                                [displayRole]="'REVIEWEE'"
                                [displayTags]="false"
                                [locale]="locale | async"
                                [displayAnswerInput]="includeAnswerPreview"
                                className="grow"></meeting-question>
            </div>
            <div class="flex items-start py-5"
                 *ngIf="questionOrNote.type === 'note'">
              <p class="mr-5 font-semibold text-grey-7">{{i+1}}.</p>
              <meeting-note [note]="questionOrNote.content"
                            [locale]="locale | async"
                            [displayTags]="false"
                            [readOnly]="true"></meeting-note>
            </div>
          </div>
        </mat-tab>
        <mat-tab>

          <ng-template mat-tab-label>
            <span>{{ 'MEETING.REVIEWER_QUESTION' | plural:getQuestionsCount('REVIEWER') | transloco | capitalize }}</span>
            <span class="question-count">{{ getQuestionsCount('REVIEWER') }}</span>
          </ng-template>
          <div *ngFor="let questionOrNote of questionsAndNotesArrayReviewer; let i = index">
            <div class="flex items-start py-5"
                 *ngIf="questionOrNote.type === 'question'">
              <p class="mr-5 font-semibold text-grey-7">{{i+1}}.</p>
              <meeting-question [question]="questionOrNote.content"
                                [readOnlyQuestion]="true"
                                [displayTags]="false"
                                [locale]="locale | async"
                                [displayRole]="'REVIEWER'"
                                [displayAnswerInput]="includeAnswerPreview"
                                className="grow"></meeting-question>
            </div>
            <div class="flex items-start py-5"
                 *ngIf="questionOrNote.type === 'note'">
              <p class="mr-5 font-semibold text-grey-7">{{i+1}}.</p>
              <meeting-note [note]="questionOrNote.content"
                            [displayTags]="false"
                            [locale]="locale | async"
                            [readOnly]="true"></meeting-note>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </section>
  </div>
</div>