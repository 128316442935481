import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notifications.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import {
  ICreateQuestionCategory,
  IPatchQuestionCategory,
  IQuestionCategory,
} from '../models/meeting-question-category.model';

@Injectable({
  providedIn: 'root',
})
export class MeetingCategoriesService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/questionCategories`;

  private categories$$: BehaviorSubject<IQuestionCategory[] | undefined> =
    new BehaviorSubject<IQuestionCategory[] | undefined>([]);
  categories$ = this.categories$$.asObservable();

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  loading$ = this._loading$$.asObservable();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _notificationService: NotificationService,
  ) {
    this._initStore();
  }

  private _initStore() {
    this.refreshStore();
  }

  refreshStore() {
    this.getAll$()
      .pipe(
        take(1),
        tap(() => this._loading$$.next(true)),
      )
      .subscribe((categories) => {
        this.categories$$.next(categories), this._loading$$.next(false);
      });
  }

  getCategoryById$(categoryId: string) {
    return this.categories$$.pipe(
      map((categories) =>
        categories.find((category) => category.id === categoryId),
      ),
    );
  }

  getAll$(): Observable<IQuestionCategory[]> {
    return this._httpClient.get<IQuestionCategory[]>(this._ENDPOINT_URL);
  }

  create$(category: ICreateQuestionCategory): Observable<IQuestionCategory> {
    return this._httpClient
      .post<IQuestionCategory>(this._ENDPOINT_URL, category)
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.CREATE_CATEGORY_SUCCESS',
          );
          this.refreshStore();
        }),
        catchError((_err) => {
          this._notificationService.showError('ALERTS.GENERIC_ERROR');
          return of(undefined);
        }),
      );
  }

  patch$(
    categoryId: string,
    categoryToPatch: IPatchQuestionCategory,
  ): Observable<IQuestionCategory> {
    return this._httpClient
      .patch<IQuestionCategory>(
        `${this._ENDPOINT_URL}/${categoryId}`,
        categoryToPatch,
      )
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.UPDATE_CATEGORY_SUCCESS',
          );
          this.refreshStore();
        }),
        catchError((_err) => {
          this._notificationService.showError('ALERTS.GENERIC_ERROR');
          return of(undefined);
        }),
      );
  }

  delete$(categoryId: string): Observable<void> {
    return this._httpClient
      .delete<void>(`${this._ENDPOINT_URL}/${categoryId}`)
      .pipe(
        tap(() => {
          this._notificationService.showSuccess(
            'MEETING.DELETE_CATEGORY_SUCCESS',
          );
          this.refreshStore();
        }),
        catchError((_err) => {
          this._notificationService.showError('ALERTS.GENERIC_ERROR');
          return of(undefined);
        }),
      );
  }
}
