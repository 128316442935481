import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IType } from '@meeting/models/meeting-type.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingTypesService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting/types`;

  private types$$: BehaviorSubject<IType[] | undefined> = new BehaviorSubject<
    IType[] | undefined
  >([]);
  types$ = this.types$$.asObservable();

  private _loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  loading$ = this._loading$$.asObservable();

  constructor(private readonly _httpClient: HttpClient) {
    this._initStore();
  }

  private _initStore() {
    this.refreshStore();
  }

  refreshStore() {
    this.getAll$()
      .pipe(
        take(1),
        tap(() => this._loading$$.next(true)),
      )
      .subscribe((types) => {
        this.types$$.next(types), this._loading$$.next(false);
      });
  }

  getAll$(): Observable<IType[]> {
    return this._httpClient.get<IType[]>(this._ENDPOINT_URL);
  }

  getTypeById$(typeId: string) {
    return this.types$.pipe(
      map((types) => types.find((type) => typeId.includes(type.id))),
    );
  }
}
